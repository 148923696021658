import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import bff, { isCancel } from 'services/bff';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import Button from 'components/base/button/button';
import CustomTableAnimalActivity from 'components/table/customTable/customTableBoAnimalActivity/customTableBoAnimalActivity';
import CustomTableHoldingActivity from 'components/table/customTable/customTableBoHoldingActivity/customTableBoHoldingActivity';
import FiltersAnimalsOnHolding from 'components/table/filters/filtersAnimalsOnHolding/filtersAnimalsOnHolding';
import FiltersBoAnimalActivity from 'components/table/filters/filtersBoAnimalActivity/filtersBoAnimalActivity';
import FiltersBoHoldingActivity from 'components/table/filters/filtersBoHoldingActivity/filtersBoHoldingActivity';
import FiltersBoSearchMovements from 'components/table/filters/filtersBoSearchMovements/filtersBoSearchMovements';
import FiltersBoSearchRequestId from 'components/table/filters/filtersBoSearchRequestId/filtersBoSearchRequestId';
import FiltersBoUserActivity from 'components/table/filters/filtersBoUserActivity/filtersBoUserActivity';
import FiltersGatheringSearch from 'components/table/filters/filtersGatheringSearch/filtersGatheringSearch';
import FiltersHoldingRegister from 'components/table/filters/filtersHoldingRegister/filtersHoldingRegister';
import FiltersUserActivity from 'components/table/filters/filtersUserActivity/filtersUserActivity';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Pagination from 'components/table/pagination/pagination';
import Spinner from 'components/base/spinner/spinner';
import { get, orderBy } from 'lodash';
const sprintf = require('util').format;

const Table = ({
  addAll,
  addAllAbove,
  classNames,
  clearAll,
  columnParams,
  columns,
  data,
  dataProvided,
  downloadPending,
  eventType,
  fetchingDataLabel,
  filtersComponents,
  generatePostCall,
  hideColumnHeaders,
  hidePagination,
  initialPageIndex,
  initialPageSize,
  initialSortBy,
  initialSortDirection,
  noDataIsError,
  noDataLabel,
  paginationParams,
  params,
  pending,
  refreshFilters,
  removeAll,
  removeAllAdded,
  removeAllInvalid,
  renderTable,
  rowLinks,
  searchState,
  setData,
  setDataProp,
  setDataTotal,
  setEventType,
  setModal,
  setRenderTable,
  store,
  title
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();
  const sessionFilters = store?.filters && storeService.session.get[`tableFilters${store.filters}`] ? storeService.session.get[`tableFilters${store.filters}`]() : null;

  let filteredSessionFilters;
  if (sessionFilters && Object.keys(sessionFilters).length > 0) {
    // eslint-disable-next-line no-unused-vars
    const { pageIndex, pageSize, sortBy, sortDirection, ...restOfSessionFilters } = sessionFilters;
    filteredSessionFilters = restOfSessionFilters;
  } else {
    filteredSessionFilters = params?.filters;
  }

  const [tableParams, setTableParams] = React.useState({
    columns: columns ? columns : [],
    filters: filteredSessionFilters ? filteredSessionFilters : {},
    page: {
      index: sessionFilters?.pageIndex ? sessionFilters.pageIndex : (initialPageIndex ? initialPageIndex : 0),
      size: sessionFilters?.pageSize ? sessionFilters.pageSize : (initialPageSize ? initialPageSize : config.DEFAULT_TABLE_PAGE_SIZE)
    },
    sort: {
      by: sessionFilters?.sortBy ? sessionFilters.sortBy : initialSortBy,
      direction: sessionFilters?.sortDirection ? sessionFilters.sortDirection : initialSortDirection
    },
    request: params?.request ? params.request : null
  });
  const [loadError, setLoadError] = React.useState(false);
  const [loadPending, setLoadPending] = React.useState(pending ? pending : (renderTable ? renderTable : true));
  const [numberOfRecords, setNumberOfRecords] = React.useState(0);
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [resultsLabel, setResultsLabel] = React.useState('');
  const [externalCancelTokenSource, setExternalCancelTokenSource] = React.useState(axios.CancelToken.source());
  const [redirectWarningOrError, setRedirectWarningOrError] = React.useState({});

  const componentClassNames = [
    !loadPending && rowLinks && data.length > 0 ? 'clickable-rows' : '',
    loadPending && data.length > 0 ? 'loadPending' : '',
    !tableParams?.columns?.find((column) => column.sortable) ? 'not-sortable' : '',
    ...(classNames ? classNames : [])
  ].join(' ').trim();

  const tableRowLinks = {
    onRowClick: (row, event) => {
      if (
        !loadPending &&
        (event.target.tagName === 'TR' || event.target.tagName === 'TD' || (event.target.tagName === 'SPAN' && event.target.className !== 'buttonLabel')) &&
        rowLinks &&
        (rowLinks.linkIf === undefined || rowLinks.linkIf(row))
      ) {
        let rowData = {
          ...row
        };
        const url = rowLinks.url(row);

        if (rowLinks.state) {
          const state = rowLinks.state(row);

          if (state.data) {
            if (Array.isArray(state.data)) {
              rowData = get(row, state.data);
            } else {
              rowData = state.data;
            }
          } else {
            Object.keys(state).forEach((key) => {
              if (Array.isArray(state[key])) {
                rowData[key] = get(row, state[key]);
              } else {
                rowData[key] = state[key];
              }
            });
          }
        }

        if (rowLinks.onClick) {
          rowLinks.onClick(row);
        }

        if (url) {
          history.push({
            pathname: rowLinks.url(row) + (rowLinks.accessor ? row[rowLinks.accessor] : ''),
            state: {
              data: rowData,
              searchState
            }
          });
        }
      }
    },
    getHint: (row, cell) => {
      if (cell.hint && typeof cell.hint === 'function') {
        return cell.hint(get(row, cell.accessor));
      }

      if (rowLinks?.hint && typeof rowLinks.hint === 'function') {
        return rowLinks.hint(row);
      }

      return null;
    },

    getRowClassNames: (row, cells) => {
      return [
        row.className,
        helpers.tag.isInvalid(row) || helpers.tag.isInvalidExtended(row) || row.duplicates?.length > 0 ? 'invalid' : '',
        ...(rowLinks?.linkIf && typeof rowLinks.linkIf === 'function' && !rowLinks.linkIf(row) ? ['no-click'] : []),
        ...(
          (rowLinks?.hint && typeof rowLinks.hint === 'function' && rowLinks.hint(row)) ||
            (cells.find((cell) => cell.hint && typeof cell.hint === 'function'))
            ? ['has-hint']
            : []
        )
      ].join(' ').trim();
    }
  };

  const render = {
    actions: () => {
      return (
        <tr className="tableActions" role="row">
          <td colSpan={tableParams.columns?.length ? tableParams.columns.length : 99}>
            {addAll && numberOfPages > 1 &&
              <Button
                buttonType="link"
                label="button.addAll"
                onClick={() => addAll(tableParams, setLoadPending, externalCancelTokenSource, setExternalCancelTokenSource)}
              />
            }

            {addAllAbove &&
              <Button
                buttonType="link"
                label="button.addAllAnimalsListedAbove"
                onClick={() => addAllAbove(data)}
              />
            }

            {removeAllInvalid && data.some((row) => helpers.tag.isInvalid(row) || helpers.tag.isInvalidExtended(row)) &&
              <Button
                buttonType="linkDanger"
                label="button.removeAllInvalid"
                onClick={removeAllInvalid}
              />
            }

            {removeAllAdded && data.filter((item) => item.added).length > 0 &&
              <Button
                buttonType="linkDanger"
                label={sprintf(t('button.removeAll'), data.filter((item) => item.added).length)}
                onClick={removeAllAdded}
              />
            }

            {removeAll &&
              <Button
                buttonType="linkDanger"
                label={sprintf(t('button.removeAll'), data.length)}
                onClick={removeAll}
              />
            }

            {clearAll && data.filter((item) => item.added).length > 0 &&
              <Button
                buttonType="linkDanger"
                label="button.clearAll"
                onClick={clearAll}
              />
            }
          </td>
        </tr>
      );
    },

    cell: (row, cell) => {
      if (cell.render) {
        return cell.render({ tableData: data, row, value: get(row, cell.accessor), t });
      }

      return get(row, cell.accessor);
    },

    customComponent: () => {
      if ((!tableParams.filters.useComponent || (Array.isArray(data) && data.length === 0) || (Object.keys(data).length === 0)) && !loadPending && !renderTable) {
        return '';
      }

      switch (tableParams.filters.useComponent) {
        case 'BoAnimalActivity':
          return (
            <CustomTableAnimalActivity
              data={data}
              pending={loadPending}
              setData={setData}
              setModal={setModal}
            />
          );

        case 'BoHoldingActivity':
          return (
            <CustomTableHoldingActivity
              data={data}
              pending={loadPending}
            />
          );

        default:
          return '';
      }
    },

    filters: (location) => {
      if (!filtersComponents) {
        return '';
      }

      switch (filtersComponents[location]) {
        case 'AnimalsOnHolding':
          return (
            <FiltersAnimalsOnHolding
              cph={tableParams.filters.cph}
              date={tableParams.filters.date}
              refreshFilters={refreshFilters}
              setModal={setModal}
              setTableParams={setTableParams}
              speciesId={tableParams.filters.speciesId}
              storeFilters={store?.filters}
              tableParams={tableParams}
            />
          );

        case 'BoAnimalActivity':
          return (
            <FiltersBoAnimalActivity
              data={data}
              hasData={(Array.isArray(data) && data?.length > 0) || Object.keys(data).length > 0}
              loadPending={loadPending}
              setData={setData}
              setRenderTable={setRenderTable}
              setTableParams={setTableParams}
              storeFilters={store?.filters}
              tableParams={tableParams}
            />
          );

        case 'BoHoldingActivity':
          return (
            <FiltersBoHoldingActivity
              eventType={eventType}
              hasData={(Array.isArray(data) && data?.length > 0) || Object.keys(data).length > 0}
              loadPending={loadPending}
              setData={setData}
              setModal={setModal}
              setRenderTable={setRenderTable}
              setTableParams={setTableParams}
              storeFilters={store?.filters}
              tableParams={tableParams}
            />
          );

        case 'BoSearchMovements':
          return (
            <FiltersBoSearchMovements
              hasData={(Array.isArray(data) && data?.length > 0)}
              loadPending={loadPending}
              setData={setData}
              setRenderTable={setRenderTable}
              setTableParams={setTableParams}
              storeFilters={store?.filters}
              tableParams={tableParams}
            />
          );

        case 'BoSearchRequestId':
          return (
            <FiltersBoSearchRequestId
              loadPending={loadPending}
              setData={setData}
              setRenderTable={setRenderTable}
              setTableParams={setTableParams}
              storeFilters={store?.filters}
              tableParams={tableParams}
            />
          );

        case 'BoUserActivity':
          return (
            <FiltersBoUserActivity
              eventType={eventType}
              hasData={(Array.isArray(data) && data?.length > 0) || Object.keys(data).length > 0}
              loadPending={loadPending}
              setData={setData}
              setModal={setModal}
              setRenderTable={setRenderTable}
              setTableParams={setTableParams}
              storeFilters={store?.filters}
              tableParams={tableParams}
            />
          );

        case 'GatheringSearch':
          return (
            <FiltersGatheringSearch
              loadPending={loadPending}
              setData={setData}
              setRenderTable={setRenderTable}
              setResultsLabel={setResultsLabel}
              setTableParams={setTableParams}
              storeFilters={store?.filters}
              tableParams={tableParams}
            />
          );

        case 'HoldingRegister':
          return (
            <FiltersHoldingRegister
              columnParams={columnParams}
              eventType={eventType}
              loadPending={loadPending}
              setData={setData}
              setEventType={setEventType}
              setModal={setModal}
              setTableParams={setTableParams}
              storeFilters={store?.filters}
              tableParams={tableParams}
            />
          );

        case 'UserActivity':
          return (
            <FiltersUserActivity
              loadPending={loadPending}
              setData={setData}
              setModal={setModal}
              setRenderTable={setRenderTable}
              setTableParams={setTableParams}
              storeFilters={store?.filters}
              tableParams={tableParams}
            />
          );

        default:
          return '';
      }
    },

    header: () => {
      return (
        <tr className="tableHeaders" role="row">
          {tableParams.columns.map((cell, cellIndex) => {
            const cellClassNames = [
              ...(cell.classNames ? cell.classNames : []),
              cell.sortable ? 'sortable' : '',
              cell.sortable && tableParams.sort.by === (cell.sortColumn ? cell.sortColumn : cell.accessor) && tableParams.sort.direction === (cell.sortReverse ? constants.sorting.descending : constants.sorting.ascending) ? 'sort-asc' : '',
              cell.sortable && tableParams.sort.by === (cell.sortColumn ? cell.sortColumn : cell.accessor) && tableParams.sort.direction === (cell.sortReverse ? constants.sorting.ascending : constants.sorting.descending) ? 'sort-desc' : '',
              cell.sortable && tableParams.sort.by !== (cell.sortColumn ? cell.sortColumn : cell.accessor) ? 'sort-both' : ''
            ].join(' ').trim();

            return (
              <th
                className={cellClassNames}
                key={'cell' + cellIndex}
                role="columnheader"
              >
                {!cell.sortable &&
                  <>
                    {typeof cell.header === 'object' ? cell.header : t(cell.header)}
                  </>
                }

                {cell.sortable &&
                  <button
                    onClick={() => setTableParams((prevState) => {
                      const sb = cell.sortColumn ? cell.sortColumn : cell.accessor;
                      const sd = prevState.sort.direction === constants.sorting.descending || prevState.sort.by !== (cell.sortColumn ? cell.sortColumn : cell.accessor) ? constants.sorting.ascending : constants.sorting.descending;

                      if (store?.filters) {
                        storeService.session.removeAll.searchResults();
                        storeService.session.set[`tableFilters${store.filters}SortBy`](sb);
                        storeService.session.set[`tableFilters${store.filters}SortDirection`](sd);
                      }

                      return {
                        ...prevState,
                        newRequest: false,
                        sort: {
                          by: sb,
                          direction: sd
                        }
                      };
                    })}
                  >
                    {typeof cell.header === 'object' ? cell.header : t(cell.header)}
                  </button>
                }
              </th>
            );
          })}
        </tr>
      );
    },

    pagination: (location) => {
      return (
        <Pagination
          columnsNumber={tableParams.columns?.length ? tableParams.columns.length : 99}
          loadPending={loadPending}
          location={location}
          numberOfPages={numberOfPages || Math.ceil(data?.length / tableParams.page.size)}
          numberOfRecords={numberOfRecords || data?.length}
          page={tableParams.page}
          paginationParams={paginationParams}
          resultsLabel={resultsLabel}
          setTableParams={setTableParams}
          store={store}
        />
      );
    },

    row: (row, rowIndex) => {
      return (
        <tr
          className={tableRowLinks.getRowClassNames(row, tableParams.columns)}
          key={'row' + rowIndex}
          onClick={(event) => tableRowLinks.onRowClick(row, event)}
          onKeyPress={(event) => tableRowLinks.onRowClick(row, event)}
          role="row"
          tabIndex={rowLinks ? '0' : null}
        >
          {tableParams.columns.map((cell, cellIndex) => {
            const cellClassNames = [
              ...(cell.classNames ? cell.classNames : [])
            ].join(' ').trim();

            return (
              <td
                className={cellClassNames}
                data-hint={tableRowLinks.getHint(row, cell)}
                key={'cell' + cellIndex}
                role="cell"
              >
                {render.cell(row, cell)}
              </td>
            );
          })}
        </tr>
      );
    },

    title: () => {
      if (!title) {
        return <></>;
      }

      return (
        <tr className="tableTitle" role="row">
          {typeof title === 'string' &&
            <th
              colSpan={columns?.length}
              role="columnheader"
            >
              {t(title)}
            </th>
          }
          {typeof title === 'object' &&
            <th
              colSpan={columns?.length}
              dangerouslySetInnerHTML={{
                __html: sprintf(
                  t(title.title),
                  ...title.data
                )
              }}
              role="columnheader"
            />
          }
        </tr>
      );
    }
  };

  const fetchData = (cancelToken) => {
    setLoadPending(true);
    setLoadError(false);

    bff
      .get(tableParams.request.url, {
        cancelToken,
        params: {
          ...tableParams.request.params,
          ...tableParams.filters,
          pageIndex: tableParams.page.index,
          pageSize: tableParams.page.size,
          sortBy: tableParams.sort.by,
          sortDirection: tableParams.sort.direction
        }
      })
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setLoadPending)) {
          if (tableParams.request.redirectTo && typeof tableParams.request.redirectTo === 'function') {
            const redirectTo = tableParams.request.redirectTo(res.data.data);

            if (redirectTo?.error) {
              setLoadPending(false);
              setRedirectWarningOrError({
                isWarning: false,
                message: redirectTo.error
              });
            } else if (redirectTo?.warning) {
              setLoadPending(false);
              setRedirectWarningOrError({
                isWarning: true,
                message: redirectTo.warning
              });
            } else if (redirectTo?.pathname) {
              history.push(redirectTo);
            }
          } else {
            setLoadPending(false);

            let dataToStore;

            if (tableParams.request.processData && typeof tableParams.request.processData === 'function') {
              dataToStore = tableParams.request.processData(res.data.data);
            } else {
              dataToStore = res.data.data;
            }

            setData(dataToStore);

            if (setDataTotal && typeof setDataTotal === 'function') {
              setDataTotal(res.data.count);
            }

            if (store?.data && storeService.session.set['searchResults' + store.data]) {
              storeService.session.set['searchResults' + store.data]({
                count: res.data.count ? res.data.count : res.data.data?.length,
                data: dataToStore
              });
            }

            if (res.data.count) {
              setNumberOfRecords(res.data.count);
            } else {
              setNumberOfRecords(res.data.data?.length);
            }
            setNumberOfPages(Math.ceil(res.data.count / tableParams.page.size));
          }
        } else {
          setLoadError(true);
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          setLoadPending(false);

          if (error?.response?.status === 404) {
            setNumberOfRecords(0);
            setNumberOfPages(0);
            setData([]);
          } else {
            errors.BFF(error, setModal);
          }
        }
      });
  };

  /* This method help to generate POST service call and bind response in the table with existing features */
  const fetchPostData = () => {
    setLoadPending(true);
    setLoadError(false);
    const postData = tableParams.request.postData;
    bff
      .post(tableParams.request.url, {
        postData,
        pageIndex: tableParams.page.index,
        pageSize: tableParams.page.size,
        sortBy: tableParams.sort.by,
        sortDirection: tableParams.sort.direction
      })
      .then((postResponse) => {
        setLoadPending(false);
        let postDataToStore;
        if (tableParams.request.processData && typeof tableParams.request.processData === 'function') {
          postDataToStore = tableParams.request.processData(postResponse.data.data);
        } else {
          postDataToStore = postResponse.data.data;
        }
        setData(postDataToStore);

        if (store?.data && storeService.session.set['searchResults' + store.data]) {
          storeService.session.set['searchResults' + store.data]({
            count: postResponse.data.count ? postResponse.data.count : postResponse.data.data?.length,
            data: postDataToStore
          });
        }

        if (postResponse.data.count) {
          setNumberOfRecords(postResponse.data.count);
        } else {
          setNumberOfRecords(postResponse.data.data?.length);
        }
        setNumberOfPages(Math.ceil(postResponse.data.count / tableParams.page.size));

      })
      .catch((error) => {
        if (!isCancel(error)) {
          setLoadPending(false);
          if (error?.response?.status === 404) {
            setNumberOfRecords(0);
            setNumberOfPages(0);
            setData([]);
          } else {
            errors.BFF(error, setModal);
          }
        }
      });
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const sessionData = store?.data && storeService.session.get[`searchResults${store.data}`] ? storeService.session.get[`searchResults${store.data}`]() : null;

    if (sessionData) {
      setNumberOfRecords(sessionData.count);
      setNumberOfPages(Math.ceil(sessionData.count / tableParams.page.size));

      if (setData && typeof setData === 'function') {
        setData(sessionData.data);
        if (setRenderTable && typeof setData === 'function') {
          setRenderTable(true);
        }
      }
      setLoadPending(false);
    } else if ((!dataProvided || tableParams.newRequest) && (renderTable || renderTable === undefined) && (!eventType?.current || (eventType?.current && tableParams.request.params.eventType))) {
      fetchData(source.token);
    } else if (generatePostCall) {
      fetchPostData();
    } else if (setData && typeof setData === 'function') {
      let sortedRecords = [];

      setData((prevState) => {
        if (setDataProp) {
          if (typeof setDataProp === 'string') {
            sortedRecords = helpers.sortArrayOfObjects(prevState[setDataProp], tableParams.sort.by, tableParams.sort.direction);

            if (store?.data && storeService.session.set[`searchResults${store.data}`]) {
              storeService.session.set[`searchResults${store.data}`]({
                count: sortedRecords?.length,
                data: sortedRecords
              });
            }

            return {
              ...prevState,
              [setDataProp]: sortedRecords
            };
          } else if (typeof setDataProp === 'object') {
            sortedRecords = helpers.sortArrayOfObjects(prevState[setDataProp.index][setDataProp.prop], tableParams.sort.by, tableParams.sort.direction);

            if (store?.data && storeService.session.set[`searchResults${store.data}`]) {
              storeService.session.set[`searchResults${store.data}`]({
                count: sortedRecords?.length,
                data: sortedRecords
              });
            }

            const returnArray = [];
            returnArray.splice(setDataProp.index, 0, {
              ...prevState[setDataProp.index],
              [setDataProp.prop]: sortedRecords
            });

            return returnArray;
          }
        }

        if (prevState?.length > 0) {
          sortedRecords = orderBy(prevState, tableParams.sort.by, tableParams.sort.direction);

          if (store?.data && storeService.session.set[`searchResults${store.data}`]) {
            storeService.session.set[`searchResults${store.data}`]({
              count: sortedRecords?.length,
              data: sortedRecords
            });
          }

          return sortedRecords;
        }

        return prevState;
      });

      setNumberOfRecords(sortedRecords?.length);
      setNumberOfPages(Math.ceil(sortedRecords?.length / tableParams.page.size));

      setLoadPending(false);
    } else {
      setNumberOfRecords(data.length);
      setNumberOfPages(Math.ceil(data.length / tableParams.page.size));
      setLoadPending(false);
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableParams]);

  useEffect(() => {
    if (renderTable && params?.request?.params) {
      setTableParams((prevState) => ({
        ...prevState,
        request: {
          ...prevState.request,
          params: params.request.params
        }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderTable]);

  useEffect(() => {
    if (dataProvided) {
      const numRecords = data?.length;
      const numPages = numRecords ? Math.ceil(data?.length / tableParams.page.size) : 1;

      setNumberOfRecords(numRecords);
      setNumberOfPages(numPages);

      if (numRecords > 0 && numRecords % tableParams.page.size === 0 && tableParams.page.index === numPages) {
        setTableParams((prevState) => ({
          ...prevState,
          page: {
            ...prevState.page,
            index: prevState.page.index - 1
          }
        }));
      } else if (numRecords < tableParams.page.size && tableParams.page.index > 0) {
        setTableParams((prevState) => ({
          ...prevState,
          page: {
            ...prevState.page,
            index: 0
          }
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    return () => externalCancelTokenSource.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready &&
        <>
          {render.filters(constants.filters.outside)}

          {(renderTable || renderTable === undefined || generatePostCall) && !tableParams.filters.useComponent &&
            <>
              {data?.length === 0 && !filtersComponents &&
                <>
                  {loadError &&
                    <ErrorLabel
                      label="error.error"
                    />
                  }
                  {!loadError &&
                    <>
                      {loadPending &&
                        <ErrorLabel
                          isWarning={true}
                          label={fetchingDataLabel ? fetchingDataLabel : 'label.fetchingData'}
                        />
                      }

                      {!loadPending &&
                        <ErrorLabel
                          isSuccess={noDataIsError === false}
                          label={noDataLabel ? noDataLabel : 'label.noData'}
                        />
                      }
                    </>
                  }
                </>
              }

              {(data?.length > 0 || (data.length === 0 && filtersComponents)) &&
                <div className="tableWrapper">
                  {(downloadPending || loadPending) && data.length > 0 &&
                    <div className="spinner">
                      <Spinner />
                    </div>
                  }

                  <table className={componentClassNames} role="table">
                    <thead>
                      {render.title()}
                      {filtersComponents?.[constants.filters.inside] &&
                        <tr className="tableFilters" role="row">
                          <th colSpan={columns?.length || tableParams.columns?.length} role="columnheader">
                            {render.filters(constants.filters.inside)}
                          </th>
                        </tr>
                      }
                      {!hidePagination && data.length > 0 && render.pagination(constants.pagination.top)}
                      {!hideColumnHeaders && data.length > 0 && render.header()}
                    </thead>

                    <tbody>
                      {data.length === 0 &&
                        <tr className={filtersComponents?.[constants.filters.inside] ? 'no-data-inside' : 'no-data'}>
                          <td colSpan={tableParams.columns?.length ? tableParams.columns.length : 99}>
                            {loadPending &&
                              <ErrorLabel
                                isWarning={true}
                                label={fetchingDataLabel ? fetchingDataLabel : 'label.fetchingData'}
                              />
                            }
                            {!loadPending &&
                              <>
                                {redirectWarningOrError?.message &&
                                  <ErrorLabel
                                    isWarning={redirectWarningOrError.isWarning}
                                    label={redirectWarningOrError.message}
                                  />
                                }

                                {!redirectWarningOrError?.message &&
                                  <ErrorLabel
                                    isSuccess={noDataIsError === false}
                                    label={noDataLabel ? noDataLabel : 'label.noData'}
                                  />
                                }
                              </>
                            }
                          </td>
                        </tr>
                      }

                      {dataProvided &&
                        <>
                          {hidePagination && data.map((row, index) => {
                            return render.row(row, index);
                          })}

                          {!hidePagination && data.slice(
                            tableParams.page.index * tableParams.page.size,
                            (tableParams.page.index * tableParams.page.size) + tableParams.page.size
                          ).map((row, index) => {
                            return render.row(row, index);
                          })}
                        </>
                      }

                      {!dataProvided && data.map((row, index) => {
                        return render.row(row, index);
                      })}
                    </tbody>

                    <tfoot>
                      {data.length > 0 &&
                        (addAllAbove || clearAll || removeAll || removeAllAdded || (removeAllInvalid && data.some((item) => helpers.tag.isInvalid(item)))) &&
                        render.actions()
                      }

                      {!hidePagination && data.length > 0 && render.pagination(constants.pagination.bottom)}
                    </tfoot>
                  </table>
                </div>
              }
            </>
          }

          {render.customComponent()}
        </>
      }
    </>
  );
};

Table.propTypes = {
  addAll: PropTypes.func,
  addAllAbove: PropTypes.func,
  classNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  clearAll: PropTypes.func,
  columnParams: PropTypes.object,
  columns: PropTypes.array,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired,
  dataProvided: PropTypes.bool,
  downloadPending: PropTypes.bool,
  eventType: PropTypes.object,
  fetchingDataLabel: PropTypes.string,
  filtersComponents: PropTypes.exact({
    [constants.filters.outside]: PropTypes.string,
    [constants.filters.inside]: PropTypes.string
  }),
  generatePostCall: PropTypes.bool,
  hideColumnHeaders: PropTypes.bool,
  hidePagination: PropTypes.bool,
  initialPageIndex: PropTypes.number,
  initialPageSize: PropTypes.number,
  initialSortBy: PropTypes.string,
  initialSortDirection: PropTypes.oneOf([
    constants.sorting.ascending,
    constants.sorting.descending
  ]),
  noDataIsError: PropTypes.bool,
  noDataLabel: PropTypes.string,
  paginationParams: PropTypes.object,
  params: PropTypes.object,
  pending: PropTypes.bool,
  refreshFilters: PropTypes.object,
  removeAll: PropTypes.func,
  removeAllAdded: PropTypes.func,
  removeAllInvalid: PropTypes.func,
  renderTable: PropTypes.bool,
  rowLinks: PropTypes.object,
  searchState: PropTypes.object,
  setData: PropTypes.func,
  setDataProp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.exact({
      index: PropTypes.number,
      prop: PropTypes.string
    })
  ]),
  setDataTotal: PropTypes.func,
  setEventType: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setRenderTable: PropTypes.func,
  store: PropTypes.exact({
    filters: PropTypes.string,
    data: PropTypes.string
  }),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

export default Table;
