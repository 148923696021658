import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import bff, { isCancel } from 'services/bff';
import classes from 'components/address/address.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import he from 'he';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';

const Address = ({
  addPadding,
  cph,
  displayCPH,
  format,
  isManual,
  queryKeeper,
  setAddressPending,
  setModal,
  setSearchedHolding,
  storeIn,
  updateAddressInStore
}) => {
  const { ready, t } = useTranslation();

  const [address, setAddress] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [pending, setPending] = React.useState(false);

  const setData = React.useCallback((inValue) => {
    const display = {
      address: (inData) => {
        if (typeof inData === 'object') {
          delete inData.holdingType;
          delete inData.holdingTypeId;
          delete inData.id;

          if (helpers.address.isShort(format?.address)) {
            setAddress(helpers.address.format.addressShort(inData));
          } else if (helpers.address.isLine(format?.address) || helpers.address.isInline(format?.address)) {
            setAddress(helpers.address.format.addressLine(inData));
          } else if (helpers.address.isOnlyAddress(format?.address)) {
            setAddress(helpers.address.format.addressWithoutHoldingName(inData));
          } else if (helpers.address.isKeeper(format?.address)) {
            setAddress(helpers.address.format.addressKeeper(inData.keeper));
          } else if (inValue === constants.address.status.FOUND_WITHOUT_ADDRESS) {
            setAddress('');
          } else {
            setAddress(helpers.address.format.addressFull(inData));
          }
        } else if (inValue === constants.address.status.FOUND_WITHOUT_ADDRESS) {
          setAddress([t(constants.address.status.FOUND_WITHOUT_ADDRESS)]);
        } else if (inValue === constants.address.status.NOT_FOUND) {
          setAddress([t(constants.address.status.NOT_FOUND)]);
        } else {
          setAddress([inData]);
        }
      },
      name: (inData) => setName(typeof inData === 'object' ? helpers.address.format.nameFull(inData) : inData),
      type: (inData) => setType(typeof inData === 'object' ? helpers.address.format.typeFull(inData) : inData)
    };

    const otherData = helpers.address.format.addressOnly(inValue) ? '' : inValue;

    display.name(format?.name && !helpers.address.isNone(format.name) ? otherData : '');
    display.type(format?.type && !helpers.address.isNone(format.type) ? otherData : '');
    display.address(format?.address && !helpers.address.isNone(format.address) ? inValue : '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [format]);

  useEffect(() => {
    let isOwnHolding = false;

    const saveHoldingAddress = (responseData, isOwnHolding, currentCPH) => {
      const sessionHoldings = storeService.session.get.holdings();
      if (isOwnHolding) {
        const holding = {
          ...sessionHoldings[currentCPH],
          address: responseData
        };
        storeService.session.set.holdings(holding, currentCPH);
      }
    };

    const fetchHoldingDetails = (currentCPH, source) => {
      bff
        .get('/holdingDetails', {
          cancelToken: source.token,
          params: {
            cph: currentCPH,
            queryKeeper
          }
        })
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal, setPending)) {
            const resData = res.data.data[0];

            if (res.data.error !== constants.error.addressMessage) {
              const hasAddress = resData.address1 || resData.address2 || resData.county || resData.postCode || resData.propertyName || resData.town;

              if (hasAddress) {
                setData(resData);
                saveHoldingAddress(resData, isOwnHolding, currentCPH);
              } else {
                setData(resData);
              }
            } else {
              setData(constants.address.status.NOT_FOUND);
            }

            if (updateAddressInStore && resData) {
              updateAddressInStore(currentCPH, resData);
            }

            if (setAddressPending) {
              setAddressPending(false);
            }

            if (setSearchedHolding) {
              setSearchedHolding({
                value: currentCPH,
                address: resData
              });
            }

            if (storeIn) {
              storeService.session.set[storeIn](resData);
            }
          }

          setPending(false);
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setPending(false);
            setData(constants.address.status.ERROR);
            errors.BFF(error, setModal);
          }
        });
    };

    const checkAddressInSessionStorage = (currentCPH, source) => {
      if (currentCPH?.length > 0) {
        setPending(true);
      }
      const holdings = storeService.session.get.holdings();
      const holdingOther = storeService.session.get.movementOtherHolding() || storeService.session.get.deathOtherHolding();
      let holding = {};

      if (holdings && holdings[currentCPH]) {
        holding = holdings[currentCPH];
        isOwnHolding = true;
      } else if (holdingOther && holdingOther.value === currentCPH) {
        holding = holdingOther;
      }

      if (holding.address) {
        setData(holding.address);
        if (setAddressPending) {
          setAddressPending(false);
        }
        if (setSearchedHolding) {
          setSearchedHolding({
            value: currentCPH,
            address: holding.address
          });
        }
        setPending(false);
      } else {
        fetchHoldingDetails(currentCPH, source);
      }
    };

    const source = axios.CancelToken.source();

    if (!cph || cph.length === 0) {
      setPending(false);
      setAddress(null);
      setName(null);
      setType(null);
    }

    if (!isManual && cph && cph.length >= config.LENGTH_HOLDING_CPH) {
      checkAddressInSessionStorage(cph, source);
    } else {
      setAddress([constants.address.status.NOT_FOUND]);
      setName(null);
      setType(null);
      setData('');
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cph]);

  return (
    <>
      {ready &&
        <>
          {displayCPH &&
            <>
              <span className={classes.bold}>{cph}</span>
              {!isManual ? ' - ' : ''}
            </>
          }

          {pending && !isManual &&
            <>
              {helpers.address.isInline(format?.address) &&
                <span className={addPadding ? classes.fetching + ' ' + classes.addPadding : classes.fetching}>{t(constants.address.status.FETCH)}</span>
              }
              {!helpers.address.isInline(format?.address) &&
                <p className={addPadding ? classes.fetching + ' ' + classes.addPadding : classes.fetching}>{t(constants.address.status.FETCH)}</p>
              }
            </>
          }

          {!pending && !isManual &&
            <>
              {type?.length > 0 &&
                <p className={classes.type}>{t(type)}</p>
              }
              {name?.length > 0 && name !== 'null' && !helpers.address.isStatusError1(name) &&
                <p data-hj-suppress className={classes.name}>{t(name)}</p>
              }

              {address?.length > 0 && address[0] !== '' &&
                <>
                  {helpers.address.withErrorAddress(address[0]) &&
                    <>
                      {helpers.address.isInline(format?.address) &&
                        <address data-hj-suppress className={addPadding ? classes.address + ' ' + classes.addPadding : classes.address}>{t(address[0])}</address>
                      }
                      {!helpers.address.isInline(format?.address) &&
                        <span data-hj-suppress>{t(address[0])}</span>
                      }
                    </>
                  }

                  {helpers.address.withAddress(address[0]) &&
                    <>
                      {helpers.address.isInline(format?.address) &&
                        <address data-hj-suppress className={addPadding ? classes.address + ' ' + classes.addPadding : classes.address}>{address[0] ? he.decode(address[0].trim()) : ''}</address>
                      }
                      {!helpers.address.isInline(format?.address) &&
                        <>
                          {address.map((line, index) => (
                            <div data-hj-suppress key={index}>{line ? he.decode(line) : ''}</div>
                          ))}
                        </>
                      }
                    </>
                  }
                </>
              }
            </>
          }
        </>
      }
    </>
  );
};

Address.propTypes = {
  addPadding: PropTypes.bool,
  cph: PropTypes.string,
  displayCPH: PropTypes.bool,
  format: PropTypes.exact({
    address: PropTypes.oneOf([
      constants.address.format.full,
      constants.address.format.short,
      constants.address.format.line,
      constants.address.format.inline,
      constants.address.format.none,
      constants.address.format.keeper,
      constants.address.format.onlyAddress
    ]),
    name: PropTypes.oneOf([
      constants.address.format.full,
      constants.address.format.inline,
      constants.address.format.none
    ]),
    type: PropTypes.oneOf([
      constants.address.format.full,
      constants.address.format.none
    ])
  }),
  isManual: PropTypes.bool,
  queryKeeper: PropTypes.bool,
  setAddressPending: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setSearchedHolding: PropTypes.func,
  storeIn: PropTypes.string,
  updateAddressInStore: PropTypes.func
};

export default Address;
