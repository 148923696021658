import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CompanyLogo from 'images/svg/logo-livestock.svg';
import CompanyPrintLogo from 'images/svg/logo-livestock-blue.svg';
import storeService from 'services/storeService';
import classes from 'components/header/banner/banner.module.scss';
import Routing from 'routing';

const useOutsideAlerter = (ref, toggleUserDropdown) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleUserDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

const Banner = ({
  setShowNav,
  showNav
}) => {
  const { ready, t } = useTranslation();

  const wrapperRef = useRef(null);

  const username = storeService.cookie.get.username();
  const toggleClassname = classes.toggleNav + ' ' + classes.close;

  const [userDropDown, setUserDropDown] = React.useState(false);

  const toggleUserDropdown = (state) => {
    if (state !== undefined) {
      setUserDropDown(state);
    } else {
      setUserDropDown(!userDropDown);
    }
  };

  useOutsideAlerter(wrapperRef, toggleUserDropdown);

  return (
    <>
      {ready &&
        <>
          <header className={classes.headerPrint}>
            <img
              alt="Livestock Information Service"
              className={classes.logo}
              src={CompanyPrintLogo}
            />
          </header>
          <header className={classes.header}>
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <Link className={classes.homeLink} tabIndex={0} to={Routing.home}>
                    <img
                      alt="Livestock Information Service"
                      className={classes.logo}
                      src={CompanyLogo}
                    />
                  </Link>
                </div>
                <div
                  className={
                    'col-sm-12 col-lg-6 d-lg-block ' +
                    classes.userInfo +
                    (showNav ? ' d-block' : ' d-none')
                  }
                >
                  <div
                    className={classes.user}
                    onClick={toggleUserDropdown}
                    onKeyPress={toggleUserDropdown}
                    ref={wrapperRef}
                    role="button"
                    tabIndex={0}
                  >
                    <i className={`bi bi-person-circle ${classes.icon}`} />
                    <span data-hj-suppress>{username}</span>

                    {userDropDown &&
                      <div className={classes.userOptions}>
                        {/*<Link className={classes.option} to="#">*/}
                        {/*  {t('label.changePassword')}*/}
                        {/*</Link>*/}
                        <Link
                          aria-label={t('label.logout')}
                          className={classes.option}
                          onBlur={toggleUserDropdown}
                          role="button"
                          tabIndex={0}
                          to={Routing.logout}
                        >
                          <i className={`bi bi-unlock ${classes.icon}`} />
                          {t('label.logout')}
                        </Link>
                      </div>
                    }
                  </div>
                </div>

                {showNav &&
                  <button
                    aria-label={t('label.openMenu')}
                    className={toggleClassname}
                    onClick={(event) => {
                      event.preventDefault();
                      setShowNav(false);
                    }}
                  />
                }
                {!showNav &&
                  <button
                    aria-label={t('label.closeMenu')}
                    className={classes.toggleNav}
                    onClick={(event) => {
                      event.preventDefault();
                      setShowNav(true);
                    }}
                  />
                }
              </div>
            </div>
          </header>
        </>
      }
    </>
  );
};

Banner.propTypes = {
  setShowNav: PropTypes.func,
  showNav: PropTypes.bool
};

export default Banner;
