import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isCancel } from 'services/bff';
import axios from 'axios';
import classes from 'components/otherHoldingSelection/otherHoldingSelection.module.scss';
import config from 'config';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import searchHelper from 'services/searchHelper';
import storeService from 'services/storeService';
import { holdingHelper } from 'services/holdingHelper';
import { tableColumns } from 'services/tableColumns';
import Button from 'components/base/button/button';
import CPHField from 'components/base/cphField/cphField';
import DropdownHoldings from 'components/dropdownHoldings/dropdownHoldings';
import DropdownSearch from 'components/base/dropdownSearch/dropdownSearch';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FieldLabel from 'components/base/fieldLabel/fieldLabel';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import Table from 'components/table/table';
import Tabs from 'components/base/tabs/tabs';
import TextEntry from 'components/base/textEntry/textEntry';

const RecentHoldings = ({
  currentHolding,
  inBusinessMovement,
  isDeath,
  movementType,
  otherHolding,
  ownHoldingKeys,
  ownHoldings,
  recentlyUsedDeathHoldings,
  recentlyUsedDepartureHoldings,
  recentlyUsedDestinationHoldings,
  setOtherHolding
}) => {
  const { ready, t } = useTranslation();

  const removeUsersHoldings = (recentlyUsedHoldings, myOwnHoldingKeys) => Object.keys(recentlyUsedHoldings)
    .filter((key) => !myOwnHoldingKeys.includes(key))
    .reduce((obj, key) => ({ ...obj, [key]: recentlyUsedHoldings[key] }), {});

  let holdings = [];

  if (isDeath) {
    holdings = recentlyUsedDeathHoldings;
  } else if (inBusinessMovement) {
    holdings = ownHoldings;
  } else if (helpers.option.movement.isOn(movementType)) {
    // Removes all the users holdings from the recently used departure holdings
    if (recentlyUsedDepartureHoldings) {
      holdings = removeUsersHoldings(recentlyUsedDepartureHoldings, ownHoldingKeys);
    }
  } else if (helpers.option.movement.isOff(movementType)) {
    // Removes all the users holdings from the recently used destination holdings
    if (recentlyUsedDestinationHoldings) {
      holdings = removeUsersHoldings(recentlyUsedDestinationHoldings, ownHoldingKeys);
    }
  }

  //Removes the users current holding from the recently used array
  if (holdings && currentHolding && currentHolding.value) {
    delete holdings[currentHolding.value];
  }

  if (holdings && Object.entries(holdings)?.length > 0) {
    return (
      <fieldset role="group">
        <div className={classes.recentlyUsedHoldings}>
          <RadioButtons
            classNames={['otherHolding']}
            ids={Object.values(holdings).map((holding) => {
              const address = helpers.address.formatLine(holding.address);
              const label = `<strong>${holding.value}</strong>${address ? ' - <span data-hj-suppress>' + address + '</span>' : ''}`;

              return {
                id: holding.value,
                label
              };
            })}
            label={t(inBusinessMovement ? 'label.myHoldings' : 'label.selectHolding')}
            name="otherHolding"
            onChange={(event) => setOtherHolding({ value: event.target.value })}
            suppressHint={true}
            value={otherHolding?.value}
          />
        </div>
      </fieldset>
    );
  }

  return (
    <>
      {ready &&
        <div className={classes.message}>
          <ErrorLabel
            isWarning={true}
            label="movements.destination.no-holdings-error"
          />
        </div>
      }
    </>
  );
};

const OtherHoldingSelection = ({
  autoSelect,
  currentHolding,
  inBusinessMovement,
  isDeath,
  isGathering,
  movementType,
  otherHolding,
  setInBusinessMovement,
  setModal,
  setMovementType,
  setOtherHolding,
  setSubmitDisabled
}) => {
  const { ready, t } = useTranslation();

  const sessionTabId = storeService.session.get.activeTabIdOtherHoldingSelection();
  const recentTabId = isDeath ? constants.tabs.recentLocations : constants.tabs.recentHoldings;
  const recentTabLabel = isDeath ? 'label.recentlyUsedLocations' : 'label.recentlyUsedHoldings';

  const [activeTabId, setActiveTabId] = React.useState(sessionTabId ? sessionTabId : constants.tabs.enterHolding);
  const [searchValue, setSearchValue] = React.useState('');
  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const [includesOwnHoldingsError, setIncludesOwnHoldingsError] = React.useState(true);
  const [sameHoldingError, setSameHoldingError] = React.useState(true);
  const [knowCPHNumber, setKnowCPHNumber] = React.useState(null);
  const [searchType, setSearchType] = React.useState(isDeath ? constants.option.knowCPHNumber.cph : null);
  const [searchNoResults, setSearchNoResults] = React.useState(false);
  const [searched, setSearched] = React.useState(false);

  const [cphNumber, setCphNumber] = React.useState('');
  const [cphNumberError, setCphNumberError] = React.useState('');
  const [fsaNumber, setFsaNumber] = React.useState('');
  const [holdingTypes, setHoldingTypes] = React.useState([]);
  const [holdingType, setHoldingType] = React.useState('');
  const [holdingName, setHoldingName] = React.useState('');
  const [holdingPostcode, setHoldingPostcode] = React.useState('');
  const [loadPending, setLoadPending] = React.useState(false);
  const [holdingTable, setHoldingTable] = React.useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);

  const recentlyUsedDeathHoldings = storeService.local.get.recentlyUsedDeathHoldings();
  const recentlyUsedDepartureHoldings = storeService.local.get.recentlyUsedDepartureHoldings();
  const recentlyUsedDestinationHoldings = storeService.local.get.recentlyUsedDestinationHoldings();
  const ownHoldings = storeService.session.get.holdings();
  const ownHoldingKeys = Object.keys(ownHoldings);
  const isAbattoir = helpers.userRole.isAbattoir(storeService.session.get.permissions());

  const clearAllInputs = () => {
    setSearchType(isDeath ? constants.option.knowCPHNumber.cph : null);
    setCphNumber('');
    setFsaNumber('');
    setHoldingType('');
    setHoldingName('');
    setHoldingPostcode('');
    setSameHoldingError(false);
  };

  const updateTab = (tabId) => {
    storeService.session.set.activeTabIdOtherHoldingSelection(tabId);
    setActiveTabId(tabId);
    clearAllInputs();
    setHoldingTable([]);
    setTotalRecords(0);
  };

  const recentHoldingPresent = (recentlyUsedHoldings, holding) => Boolean(recentlyUsedHoldings && recentlyUsedHoldings[holding]);

  const searchForHolding = (isGathering) => {
    setHoldingTable([]);
    setTotalRecords(0);
    setSameHoldingError(false);
    const holding = storeService.session.get.holding();
    if (holding?.value === cphNumber) {
      setSameHoldingError(true);
      return;
    }
    setIncludesOwnHoldingsError(false);
    let query = {};
    if (helpers.cph.knownCPH.isYes(knowCPHNumber)) {
      // validate the CPH or FSA search field
      if (cphNumber && helpers.validate.cphNumber(cphNumber)) {
        query.cph = cphNumber;
      } else if (fsaNumber && helpers.validate.fsaNumber(fsaNumber)) {
        query.fsa = fsaNumber;
      }
    } else if (cphNumber && helpers.validate.cphNumber(cphNumber)) {
      query.cph = cphNumber;
    } else {
      query = { holdingType, holdingName, holdingPostcode };
    }

    searchHelper.searchCPH(query, setSearched, setSearchNoResults)
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal)) {
          const searchByCPH = query.cph || query.fsa;

          const resData = searchByCPH ? res.data.data?.[0] : res.data.data;

          const buildHoldingItem = (holding) => ({
            cph: helpers.replaceNull(holding.id),
            keeperName: [holding.title, holding.firstName, holding.lastName].filter(Boolean).join(' '),
            holdingName: helpers.replaceNull(holding.propertyName),
            holdingAddress: [holding.address1, holding.address2, holding.town, holding.county, holding.postCode].filter(Boolean).join(', '),
            holdingAddressObject: {
              propertyName: holding.propertyName,
              address1: holding.address1,
              address2: holding.address2,
              town: holding.town,
              county: holding.county,
              postCode: holding.postCode
            },
            holdingPostcode: helpers.replaceNull(holding.postCode),
            holdingType: helpers.replaceNull(holding.holdingType.fullName)
          });

          if (isGathering && res.data.error === constants.error.addressMessage && searchByCPH) {
            if (helpers.cph.knownCPH.isCph(searchType) || helpers.cph.knownCPH.isFsa(searchType) || !searchType) {
              setOtherHolding({
                value: searchByCPH,
                address: { address1: t('label.cphNotFound') }
              });
              setSearchNoResults(false);
            }
          } else if (resData) {
            const holdingResult = searchByCPH ? [buildHoldingItem(resData)] : resData.map((holding) => buildHoldingItem(holding));

            setTotalRecords(res.data.count);

            const filteredResult = holdingResult.filter((holding) => holding.cph !== currentHolding.value);

            setHoldingTable(filteredResult);
            if (filteredResult?.length === 0) {
              if (searchByCPH) {
                setIncludesOwnHoldingsError(holdingResult?.length === 1);
              } else {
                setSearchNoResults(true);
              }
            }

            if ((autoSelect && helpers.cph.knownCPH.isYes(knowCPHNumber)) ||
              (filteredResult?.length === 0 && ownHoldings[filteredResult[0].cph])) {
              handleChanges.selectHolding(filteredResult[0], filteredResult);
            }
          } else {
            setSearchNoResults(true);
          }
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          setSearchNoResults(true);
        }
      });
  };

  const confirmSearchCorrect = (holding, isManual, allResults) => {
    const updateHolding = holding.value ? holding.value.trim() : null;

    if (updateHolding) {
      setOtherHolding(isManual ? { ...holding, isManual: true } : holding);

      if (isDeath && !recentHoldingPresent(recentlyUsedDeathHoldings, holding.value)) {
        storeService.local.set.recentlyUsedDeathHoldings({
          ...recentlyUsedDeathHoldings,
          [updateHolding]: {
            ...holding,
            address: allResults ? allResults.find((item) => item.cph === holding.value)?.holdingAddressObject : holdingTable.find((item) => item.cph === holding.value)?.holdingAddressObject,
            isManual
          }
        });
        if (setSubmitDisabled && typeof setSubmitDisabled === 'function') {
          setSubmitDisabled(false);
        }
      } else if (helpers.option.movement.isOn(movementType) && !recentHoldingPresent(recentlyUsedDepartureHoldings, holding.value)) {
        storeService.local.set.recentlyUsedDepartureHoldings({
          ...recentlyUsedDepartureHoldings,
          [updateHolding]: {
            ...holding,
            address: allResults ? allResults.find((item) => item.cph === holding.value)?.holdingAddressObject : holdingTable.find((item) => item.cph === holding.value)?.holdingAddressObject
          }
        });
      } else if (helpers.option.movement.isOff(movementType) && !recentHoldingPresent(recentlyUsedDestinationHoldings, holding.value)) {
        storeService.local.set.recentlyUsedDestinationHoldings({
          ...recentlyUsedDestinationHoldings,
          [updateHolding]: {
            ...holding,
            address: allResults ? allResults.find((item) => item.cph === holding.value)?.holdingAddressObject : holdingTable.find((item) => item.cph === holding.value)?.holdingAddressObject
          }
        });
      }

      setSearchValue('');
      updateTab(activeTabId);
    }
  };

  const handleChanges = {
    selectHolding: (row, allResults) => {
      const selectedCPH = row.cph;
      if (!inBusinessMovement && ownHoldings[selectedCPH] && setMovementType) {
        setInBusinessMovement(true);
        storeService.session.set.movementInBusiness(true);
      } else {
        setSearchValue(selectedCPH);
        confirmSearchCorrect({ value: selectedCPH }, false, allResults);
      }

      clearAllInputs();
      setOtherHolding({
        value: selectedCPH,
        address: row.holdingAddressObject
      });
    },

    updateCPHFSAFlag: (cphFsaFlag) => {
      setSameHoldingError(false);
      setKnowCPHNumber(cphFsaFlag);
      setIncludesOwnHoldingsError(false);

      if (helpers.cph.knownCPH.isNo(cphFsaFlag)) {
        setSearchType(null);
        setFsaNumber('');
        setCphNumber('');
        setButtonDisabled(true);
      } else if (helpers.cph.knownCPH.isYes(cphFsaFlag)) {
        setSearchType(null);
        setHoldingType('');
        setHoldingName('');
        setHoldingPostcode('');
        setButtonDisabled(true);
      }

      setHoldingTable([]);
      setTotalRecords(0);
      setSearched(false);
    },

    updateSearchType: (searchTypeFlag) => {
      setSameHoldingError(false);
      setIncludesOwnHoldingsError(false);
      setSearchType(searchTypeFlag);
      setSearched(false);
      setFsaNumber('');
      setCphNumber('');
      setButtonDisabled(true);
    },

    updateCphNumber: (cph) => {
      const status = helpers.validate.cphNumber(cph);
      setCphNumberError('');
      setSearchNoResults(false);
      setCphNumber(cph);
      setTotalRecords(0);
      setButtonDisabled(!status);
    },

    updateFsaNumber: (newFsaNumber) => {
      const status = helpers.validate.fsaNumber(newFsaNumber);
      setFsaNumber(newFsaNumber);
      setButtonDisabled(!status);
    },

    updateHoldingType: (newHoldingType) => {
      const status = holdingName?.length > 0 || holdingPostcode?.length >= 2;
      setHoldingType(newHoldingType);
      setButtonDisabled(!status);
    },

    updateHoldingName: (newHoldingName) => {
      const status = newHoldingName?.length > 0 || holdingPostcode?.length >= 2;
      setHoldingName(newHoldingName);
      setButtonDisabled(!status);
    },

    updateHoldingPostcode: (newHoldingPostcode) => {
      const status = holdingName?.length > 0 || newHoldingPostcode?.length >= 2;
      setHoldingPostcode(newHoldingPostcode);
      setButtonDisabled(!status);
    }
  };

  // const getClassNames = (holding) => {
  //   const results = [classes.holdingListRow];
  //   if (otherHolding && otherHolding.value === holding[1].value) {
  //     results.push(classes.holdingListRowSelected);
  //   }
  //   return results.join(' ').trim();
  // };

  // const updateAddressInStore = (cph, address) => {
  //   if (isDeath) {
  //     recentlyUsedDeathHoldings[cph] = { value: cph, address };
  //     storeService.local.set.recentlyUsedDeathHoldings(recentlyUsedDeathHoldings);
  //   } else if (helpers.option.movement.isOn(movementType)) {
  //     if (recentlyUsedDepartureHoldings) {
  //       recentlyUsedDepartureHoldings[cph] = { value: cph, address };
  //       storeService.local.set.recentlyUsedDepartureHoldings(recentlyUsedDepartureHoldings);
  //     }
  //   } else if (helpers.option.movement.isOff(movementType)) {
  //     if (recentlyUsedDestinationHoldings) {
  //       recentlyUsedDestinationHoldings[cph] = { value: cph, address };
  //       storeService.local.set.recentlyUsedDestinationHoldings(recentlyUsedDestinationHoldings);
  //     }
  //   }
  // };

  // const getAddressFromStore = (cph) => {
  //   if (isDeath) {
  //     return recentlyUsedDeathHoldings[cph] ? recentlyUsedDeathHoldings[cph].address : null;
  //   } else if (helpers.option.movement.isOn(movementType)) {
  //     return recentlyUsedDepartureHoldings && recentlyUsedDepartureHoldings[cph] ? recentlyUsedDepartureHoldings[cph].address : null;
  //   } else if (helpers.option.movement.isOff(movementType)) {
  //     return recentlyUsedDestinationHoldings && recentlyUsedDestinationHoldings[cph] ? recentlyUsedDestinationHoldings[cph].address : null;
  //   }
  //   return null;
  // };

  const display = {
    searchByCphFsa: (isMovement) => {
      const viewType = (isMovement) ? searchType : constants.option.knowCPHNumber.cph;

      return (
        <div className="section">
          <div className={classes.singleSearch}>
            {(
              helpers.cph.knownCPH.isCph(viewType) || isAbattoir
            ) &&
              <div className={classes.cphNumber}>
                <CPHField
                  classNames={['data-hj-allow']}
                  cph={cphNumber}
                  error={cphNumberError}
                  id="holdingCPH"
                  label="movements.destination.cph-number"
                  setCph={handleChanges.updateCphNumber}
                />
              </div>
            }

            {helpers.cph.knownCPH.isFsa(viewType) &&
              <div className={classes.fsaNumber}>
                <TextEntry
                  hideCharacterCount={true}
                  id="holdingFSA"
                  label="movements.destination.fas-number"
                  maxLength={4}
                  maxWidth={true}
                  name="holding-fsa"
                  onChange={(event) => handleChanges.updateFsaNumber(event.target.value)}
                  type="text"
                  value={fsaNumber}
                />
              </div>
            }

            {(
              viewType !== null || isAbattoir
            ) &&
              <div className={classes.action}>
                <Button
                  buttonType="secondary"
                  disabled={buttonDisabled}
                  id="search-by-holding-detail"
                  label={isGathering ? 'button.select' : 'movements.cph-search-title'}
                  onClick={() => searchForHolding(isGathering)}
                />
              </div>
            }
          </div>
        </div>
      );
    },

    searchByHoldingDetail: (isMovement) => {
      return (
        <div className={classes.multiSearch}>
          {isMovement &&
            <div className={classes.holdingTypeDropdown}>
              <DropdownSearch
                id="holdingsDropdown"
                isSearchable={true}
                label="movements.destination.holding-type"
                name="holding-type"
                onChange={(event) => handleChanges.updateHoldingType(event.target.value)}
                optional={true}
                optionList={holdingTypes}
                placeholder="prompt.pleaseSelect"
                value={holdingType}
              />
            </div>
          }
          <div className={classes.holdingName}>
            <TextEntry
              id="holdingName"
              label="movements.destination.holding-name"
              name="holding-name"
              onChange={(event) => handleChanges.updateHoldingName(event.target.value)}
              type="text"
              value={holdingName}
            />
          </div>
          <div className={classes.holdingPostcode}>
            <TextEntry
              id="holdingPostcode"
              label="movements.destination.holding-postcode"
              name="holding-postcode"
              onChange={(event) => handleChanges.updateHoldingPostcode(event.target.value)}
              tooltip="movements.destination.holding-postcode-hint"
              type="text"
              value={holdingPostcode}
            />
          </div>
          <div className={classes.action}>
            <Button
              buttonType="secondary"
              className="col-2"
              disabled={buttonDisabled}
              id="search-by-holding-detail"
              label="movements.cph-search-title"
              onClick={() => searchForHolding(false)}
            />
          </div>
        </div>
      );
    },

    searchManual: () => {
      return (
        <div>
          <div className={classes.singleSearch}>
            <div className={classes.cphNumber}>
              <CPHField
                classNames={['data-hj-allow']}
                cph={cphNumber}
                id="holdingCPH"
                label="movements.destination.cph-number"
                setCph={handleChanges.updateCphNumber}
              />
            </div>
            <div className={classes.action}>
              <Button
                buttonType="secondary"
                disabled={buttonDisabled}
                id="search-by-holding-detail"
                label="button.select"
                onClick={() => searchForHolding(isGathering)}
              />
            </div>
          </div>
          <div>
            {sameHoldingError &&
              <ErrorLabel label="error.sameHoldingForMovement" />
            }
          </div>
        </div>
      );
    },

    searchResults: () => {
      return (
        <>
          {includesOwnHoldingsError &&
            <ErrorLabel
              label="error.ownHolding"
            />
          }

          {searched &&
            <>
              {searchNoResults &&
                <ErrorLabel
                  error={true}
                  label={isDeath ? 'label.cphNotFoundDeaths' : 'label.cphNotFound'}
                />
              }
              {totalRecords > 50 &&
                <div id="holdingsCountWarning">
                  <ErrorLabel
                    isWarning={true}
                    label="warning.holdingsCountWarning"
                  />
                </div>
              }
              {holdingTable?.length > 0 &&
                <Table
                  columns={tableColumns.holdingSelection({ isDeath, selectHolding: handleChanges.selectHolding })}
                  data={holdingTable}
                  dataProvided={true}
                  hidePagination={true}
                  setData={setHoldingTable}
                  setModal={setModal}
                />
              }
            </>
          }
        </>
      );
    }
  };

  useEffect(() => {
    if (!loadPending) {
      const storeHoldingTypes = null;
      if (storeHoldingTypes?.length > 0) {
        setHoldingTypes(storeHoldingTypes);
      } else {
        helpers.get.holdingTypes(
          setModal,
          axios.CancelToken.source(),
          setLoadPending,
          setHoldingTypes
        );
        if (holdingTypes?.length > 0) {
          storeService.local.set.holdingTypesDropdown(holdingTypes);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingTypes]);

  useEffect(() => {
    const holding = storeService.session.get.holding();
    if (searchValue && holding?.value === searchValue) {
      setSameHoldingError(true);
      setIncludesOwnHoldingsError(false);
      setButtonDisabled(true);
    } else {
      setSameHoldingError(false);
      const isOwnHolding = searchValue && ownHoldingKeys.includes(searchValue);

      setIncludesOwnHoldingsError(!isDeath && isOwnHolding);
      setButtonDisabled(!searchValue || (!isDeath && isOwnHolding));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setCphNumberError('');

    if (helpers.option.movement.isOff(movementType) && Object.keys(ownHoldings).find((holding) => holding === cphNumber && !holdingHelper.cph.isEnglish(holding))) {
      setCphNumberError('error.xBorderInBusinessOffMovement');
      setButtonDisabled(true);
    } else {
      const status = helpers.validate.cphNumber(cphNumber);
      setButtonDisabled(!status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movementType, cphNumber]);

  return (
    <>
      {ready &&
        <>
          {inBusinessMovement &&
            <div className={`section ${classes.holdingsDropdown}`}>
              <DropdownHoldings
                label={helpers.option.movement.isOn ? 'label.selectFromYourHoldingsDestination' : 'label.selectFromYourHoldingsDeparture'}
                name="dropdownHoldings"
                onChange={(event) => setOtherHolding({
                  value: event.target.value.trim()
                })}
              />
            </div>
          }

          {!inBusinessMovement &&
            <Tabs activeTabId={activeTabId} setActiveTabId={updateTab}>
              <div id={constants.tabs.enterHolding} label={isDeath ? 'label.locationIsCPH' : 'label.searchForCph'}>
                <div className="section">
                  <RadioButtons
                    ids={[
                      constants.option.knowCPHNumber.yes,
                      constants.option.knowCPHNumber.no
                    ]}
                    inline={true}
                    label={isDeath || isAbattoir ? 'radioButtons.knowCphSelection.label' : 'radioButtons.knowCphFsaSelection.label'}
                    name={isDeath || isAbattoir ? 'knowCphSelection' : 'knowCphFsaSelection'}
                    onChange={(event) => handleChanges.updateCPHFSAFlag(event.target.value)}
                    suppressHint={true}
                    suppressLabel={false}
                    value={knowCPHNumber}
                  />
                </div>

                {helpers.cph.knownCPH.isYes(knowCPHNumber) &&
                  <>
                    {!isDeath && !isAbattoir &&
                      <div className="section">
                        <RadioButtons
                          ids={[
                            constants.option.knowCPHNumber.cph,
                            constants.option.knowCPHNumber.fsa
                          ]}
                          inline={true}
                          label="radioButtons.cphFsaSelection.label"
                          name="cphFsaSelection"
                          onChange={(event) => handleChanges.updateSearchType(event.target.value)}
                          value={searchType}
                        />
                      </div>
                    }

                    {display.searchByCphFsa(!isDeath)}
                  </>
                }

                {helpers.cph.knownCPH.isNo(knowCPHNumber) &&
                  <div className="section">
                    {display.searchByHoldingDetail(!isDeath)}
                  </div>
                }

                {sameHoldingError && !isDeath &&
                  <ErrorLabel
                    label="error.sameHoldingForMovement"
                  />
                }
                {sameHoldingError && isDeath &&
                  <ErrorLabel
                    label="error.sameHoldingForDeath"
                  />
                }
                {display.searchResults()}
              </div>

              {!isGathering &&
                <div id={recentTabId} label={recentTabLabel}>
                  <RecentHoldings
                    currentHolding={currentHolding}
                    isDeath={isDeath}
                    movementType={movementType}
                    otherHolding={otherHolding}
                    ownHoldingKeys={ownHoldingKeys}
                    ownHoldings={ownHoldings}
                    recentlyUsedDeathHoldings={recentlyUsedDeathHoldings}
                    recentlyUsedDepartureHoldings={recentlyUsedDepartureHoldings}
                    recentlyUsedDestinationHoldings={recentlyUsedDestinationHoldings}
                    setOtherHolding={setOtherHolding}
                  />
                </div>
              }

              {isGathering &&
                <div id={constants.tabs.manualEntry} label="label.manualEntryTab">
                  <div className="section">
                    {display.searchManual()}

                    {display.searchResults()}
                  </div>
                </div>
              }

              {isDeath &&
                <div id={constants.tabs.nonCph} label="label.locationIsNotCPH">
                  <FieldLabel
                    label={isDeath
                      ? 'label.enterNonCphLocationDetails'
                      : 'label.manualEntryHint'
                    }
                    name="holdingManualEntry"
                    optional={isDeath}
                  />
                  <div className={classes.search}>
                    <div className={classes.input}>
                      <TextEntry
                        className={classes.manualEntry}
                        id="holdingManualEntry"
                        maxLength={config.MAXLENGTH_NON_CPH_LOCATION}
                        maxWidth={true}
                        name="holding-manual-entry"
                        onChange={(event) => {
                          if (isDeath && setSubmitDisabled && typeof setSubmitDisabled === 'function') {
                            setSubmitDisabled(true);
                          }
                          setSearchValue(event.target.value);
                        }}
                        type="text"
                        value={searchValue}
                      />
                    </div>
                    <Button
                      buttonType="secondary"
                      disabled={buttonDisabled}
                      label="button.add"
                      onClick={() => confirmSearchCorrect({ value: searchValue }, true)}
                    />
                  </div>
                  {includesOwnHoldingsError &&
                    <ErrorLabel
                      label="error.ownHolding"
                    />
                  }
                  {sameHoldingError &&
                    <ErrorLabel
                      label="error.sameHoldingForDeath"
                    />
                  }
                </div>
              }
            </Tabs>
          }
        </>
      }
    </>
  );
};

OtherHoldingSelection.propTypes = {
  autoSelect: PropTypes.bool,
  currentHolding: PropTypes.object,
  inBusinessMovement: PropTypes.bool,
  isDeath: PropTypes.bool,
  isGathering: PropTypes.bool,
  movementType: PropTypes.string,
  otherHolding: PropTypes.object,
  setInBusinessMovement: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setMovementType: PropTypes.func,
  setOtherHolding: PropTypes.func.isRequired,
  setSubmitDisabled: PropTypes.func
};

export default OtherHoldingSelection;
