import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classes from 'components/base/textEntry/textEntry.module.scss';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FieldLabel from 'components/base/fieldLabel/fieldLabel';
import Hint from 'components/base/hint/hint';
import he from 'he';

const TextEntry = ({
  classNames,
  disabled,
  encrypted,
  errorLabel,
  fieldDescription,
  hideCharacterCount,
  hint,
  id,
  label,
  label2,
  max,
  maxLength,
  maxWidth,
  min,
  name,
  onBlur,
  onChange,
  onKeyDown,
  onKeyUp,
  onMouseLeave,
  optional,
  placeholder,
  readOnly,
  reference,
  rows,
  tooltip,
  tooltipPosition,
  type,
  value,
  warningLabel,
  width
}) => {
  const { ready, t } = useTranslation();

  const inputType = encrypted ? 'password' : (type ? type : 'text');

  const [lastKey, setLastKey] = useState();

  const labelClassNames = [
    ...(classNames ? classNames : [])
  ];

  const inputClassNames = [
    classes.textbox,
    ...(classNames ? classNames.map((className) => (className === 'data-hj-allow' ? className : classes[className])) : []),
    maxWidth ? classes.maxWidth : '',
    width ? classes[width] : ''
  ].join(' ').trim();

  const areaClassNames = [
    ...(classNames ? classNames.map((className) => classes[className]) : []),
    classes.textarea
  ].join(' ').trim();

  const countClassNames = [
    maxLength && !hideCharacterCount ? classes.count : '',
    width ? classes[width] : ''
  ].join(' ').trim();

  const handleChanges = {
    onKeyDownHandlerInput: (event) => {
      if (onKeyDown) {
        onKeyDown(event);
      } else if (event.key >= 0 && lastKey !== 'Tab' && event.target.value.length === event.target.maxLength) {
        event.preventDefault();
      }

      setLastKey(event.key);
    },

    onKeyDownHandlerTextarea: (event) => {
      if (event.keyCode === 13 && event.target.value.split('\n').length >= parseInt(maxLength)) {
        event.preventDefault();
      }
    }
  };

  return (
    <>
      {ready &&
        <>
          <FieldLabel
            classNames={labelClassNames}
            label={label}
            label2={label2}
            name={id}
            optional={optional}
            tooltip={tooltip}
            tooltipPosition={tooltipPosition}
          />
          {fieldDescription &&
            <span className={classes.fieldDescription}>{t(fieldDescription)}</span>
          }
          <Hint
            hint={hint}
          />
          <ErrorLabel
            id={id}
            label={errorLabel}
          />
          <ErrorLabel
            id={id + 'Warning'}
            isWarning={true}
            label={warningLabel}
          />
          {readOnly &&
            <input
              className={inputClassNames}
              disabled="disabled"
              type={inputType}
              value={value}
            />
          }
          {!readOnly && (!rows || rows === 1) &&
            <div className={countClassNames} data-count={maxLength ? (maxLength - (value?.length ? value.length : 0)) : ''}>
              <input
                className={inputClassNames}
                disabled={disabled}
                id={id}
                max={max}
                maxLength={maxLength}
                min={min}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onKeyDown={handleChanges.onKeyDownHandlerInput}
                onKeyUp={onKeyUp}
                onMouseLeave={onMouseLeave}
                placeholder={t(placeholder)}
                ref={reference}
                type={inputType}
                value={value ? he.decode(value) : ''}
              />
            </div>
          }
          {rows && rows > 1 &&
            <div className={countClassNames} data-count={maxLength ? (maxLength - (value?.length ? value.length : 0)) : ''}>
              <textarea
                className={areaClassNames}
                disabled={disabled}
                id={id}
                maxLength={maxLength}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onKeyDown={handleChanges.onKeyDownHandlerTextarea}
                ref={reference}
                rows={rows}
                value={value ? he.decode(value) : ''}
              />
            </div>
          }
        </>
      }
    </>
  );
};

TextEntry.propTypes = {
  classNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  disabled: PropTypes.bool,
  errorLabel: PropTypes.string,
  fieldDescription: PropTypes.string,
  hideCharacterCount: PropTypes.bool,
  hint: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  label2: PropTypes.string,
  max: PropTypes.number,
  maxLength: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  maxWidth: PropTypes.bool,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onMouseLeave: PropTypes.func,
  optional: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  reference: PropTypes.string,
  rows: PropTypes.number,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  warningLabel: PropTypes.string,
  width: PropTypes.string
};

export default TextEntry;
